import {Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild} from '@angular/core';
import {OnBoardingInfo} from '../core/models/models';
import {Router} from '@angular/router';
import {PhoneVerificationComponent} from '../views-reseller/reseller-common/phone-verification.component';
import { IconSourcePipe } from '../shared/pipes';

@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.scss']
})
export class OnBoardingComponent implements OnInit {
  @ViewChild(PhoneVerificationComponent, {static: true}) phoneVerificationModal: PhoneVerificationComponent;
  @Output() updateEvent = new EventEmitter<void>();
  showElement = false;
  onBoardingInfo: OnBoardingInfo;
  currentStage = '';
  currentStageName = '';
  blockCloseFlag = false;


  stages = [
    {
      name: 'onboarding.stages.completeProfile',
      completeImage: 'onboarding-profile-complete',
      incompleteImage: 'onboarding-profile-incomplete',
      key: 'profile',
      action: 'stage',
      value: '',
      blockClose: false,
    },
    {
      name: 'onboarding.stages.payBalance',
      completeImage: 'onboarding-balance-complete',
      incompleteImage: 'onboarding-balance-incomplete',
      key: 'balance',
      action: 'link',
      value: 'saldo-y-pagos',
      blockClose: false,
    },
    {
      name: 'onboarding.stages.enterpriseData',
      completeImage: 'onboarding-store-complete',
      incompleteImage: 'onboarding-store-incomplete',
      key: 'business',
      action: 'stage',
      value: '',
      blockClose: false,
    },
  ];

  @ViewChild('pakkeToast', {read: ElementRef, static: true}) private pakkeToast: ElementRef;

  constructor(
    private router: Router,
    private iconSourcePipe: IconSourcePipe
  ) {}

  ngOnInit() {
    window.addEventListener('click', (e: any) => {
      if (!this.pakkeToast.nativeElement.contains(e.target) && this.showElement
        && !this.blockCloseFlag && e.target.className !== 'mat-option-text' &&
        e.target.className !== 'ng-option ng-option-child ng-star-inserted ng-option-marked'
        && e.target.innerText !== 'Ingresar dirección manualmente' && e.target.innerText !== ' Direcciones guardadas ' && e.target.className !== 'ng-option ng-star-inserted ng-option-marked'
        && e.target.className !== 'ng-option ng-option-marked ng-star-inserted') {
        // Clicked outside the box
        this.hide();
      }
    });
  }

  show(data: OnBoardingInfo) {
    if (this.showElement) {
      this.hide();
      return;
    }
    setTimeout(() => {
      this.setOboardingData(data);
      this.showElement = true;
    }, 50);
  }

  setOboardingData(data: OnBoardingInfo) {
    this.onBoardingInfo = data;
  }

  hide() {
    this.showElement = false;
    this.onBoardingInfo = null;
    this.backToSart();
  }

  backToSart() {
    this.updateData();
    this.blockCloseTmp();
    this.currentStage = '';
    this.currentStageName = '';
  }

  executeStage(stage) {
    if (stage.key == 'profile') {
      if (!(this.onBoardingInfo.status) || this.onBoardingInfo.status == 'UNVERIFIED') {
        // document.getElementById('verify-btn').click(); // Pakke Verificaton
        document.getElementById('metamap_verif_btn').click(); // Metamap Verification
      }
      return;
    }
    if (!stage) {
      return;
    }
    if (!stage.blockClose) {
      this.blockCloseTmp();
    } else {
      this.blockCloseFlag = true;
    }
    if (stage.action === 'stage') {
      this.currentStage = stage.key;
      this.currentStageName = stage.name;
    } else if (stage.action === 'link') {
      this.hide();
      this.router.navigate([stage.value]);
    }
  }

  getStepImage(stage) {
    if (this.onBoardingInfo[stage.key]) {
      if (stage.key === 'profile' && (this.onBoardingInfo.status !== 'VERIFIED')) {
        return this.iconSourcePipe.transform('onboarding-profile-unverified');
      }
      return this.iconSourcePipe.transform(stage.completeImage);
    }
    return this.iconSourcePipe.transform(stage.incompleteImage);
  }

  completeProfile() {
    if (!this.onBoardingInfo) {
      return;
    }
    for (const stage of this.stages) {
      if (!this.onBoardingInfo[stage.key]) {
        this.executeStage(stage);
        return;
      }
    }
  }

  private blockCloseTmp() {
    this.blockCloseFlag = true;
    setTimeout(() => {
      this.blockCloseFlag = false;
    }, 500);
  }

  updateData() {
    this.updateEvent.emit();
  }

}
